<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      fill="currentColor"
      d="M13.65 13.375 12.575 12.3l2.55-2.55h-8.75v-1.5h8.7l-2.55-2.55L13.6 4.625l4.4 4.4-4.35 4.35ZM0 18V0h8.775v1.5H1.5v15h7.275V18H0Z"
    />
  </svg>
</template>

